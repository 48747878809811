<template>
  <div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <div class="table_box">
        <Form
          ref="formValidate"
          :label-width="100"
          label-position="right"
          @submit.native.prevent>
          <Row :gutter="24" type="flex" justify="end">
            <Col span="24" class="ivu-text-left">
              <FormItem :label="fromList.title + '：'">
                <RadioGroup type="button" v-model="formValidate.data" class="l-m-r15" @on-change="handleSelectChange(formValidate.data)">
                  <Radio :label="itemn.val" v-for="(itemn, indexn) in fromList.fromTxt" :key="indexn">{{ itemn.text }}</Radio>
                </RadioGroup>
                <DatePicker :editable="false" @on-change="handleChangeTime" :value="timeVal" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="请选择时间" style="width: 200px"></DatePicker>
              </FormItem>
            </Col>
            <Col span="24" class="ivu-text-left">
              <Row>
                <Col v-bind="grid">
                  <FormItem label="名称：">
                    <Select class="l-perW90" v-model="formValidate.admin_id" clearable @on-change="handleSearch">
                      <Option :value="item.id" v-for="(item, index) in dataList" :key="index">{{item.real_name}}</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col v-bind="grid">
                  <FormItem label="行为：">
                    <Input class="l-perW90" placeholder="请输入行为" v-model="formValidate.pages" clearable></Input>
                  </FormItem>
                </Col>
                <Col v-bind="grid">
                  <FormItem label="链接：">
                    <Input class="l-perW90" placeholder="请输入链接" v-model="formValidate.path" clearable></Input>
                  </FormItem>
                </Col>
                <Col v-bind="grid">
                  <FormItem label="IP：">
                    <Input class="l-perW90" placeholder="请输入IP" v-model="formValidate.ip" clearable></Input>
                  </FormItem>
                </Col>
                <Col v-bind="grid">
                  <Button type="primary" icon="ios-search" label="default" class="userSearch" @click="handleSearch">搜索</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>

      <Table
        ref="selection"
        :columns="columns"
        :data="tabList"
        :loading="loading"
        no-data-text="暂无数据"
        highlight-row
        no-filtered-data-text="暂无筛选结果">
        <template slot-scope="{ row }" slot="nickname">
          <span>{{ row.admin_id + ' / ' + row.admin_name }}</span>
        </template>
        <template slot-scope="{ row }" slot="add_time">
          <span> {{ row.add_time | formatDate }}</span>
        </template>
      </Table>
      <div class="acea-row row-right l-m-t25">
        <Page :total="total" :current="formValidate.page" show-elevator show-total @on-change="handlePageChange" :page-size="formValidate.limit"/>
      </div>
    </Card>
  </div>
</template>

<script>
import mixins from '@/mixins'
import { formatDate } from '@/utils'
import {
  getSystemListApi,
  searchAdminApi
} from '@/api/system'

export default {
  name: 'index',
  mixins: [mixins],
  data () {
    return {
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [
          { text: '全部', val: '' },
          { text: '今天', val: 'today' },
          { text: '昨天', val: 'yesterday' },
          { text: '最近7天', val: 'lately7' },
          { text: '最近30天', val: 'lately30' },
          { text: '本月', val: 'month' },
          { text: '本年', val: 'year' }
        ]
      },
      timeVal: [],
      formValidate: {
        limit: 20,
        page: 1,
        pages: '',
        data: '',
        path: '',
        ip: '',
        admin_id: ''
      },

      dataList: [],
      tabList: [],
      columns: [
        {
          title: 'ID',
          key: 'id',
          width: 80
        },
        {
          title: 'ID/名称',
          slot: 'nickname',
          minWidth: 100
        },
        {
          title: '行为',
          key: 'page',
          minWidth: 150
        },
        {
          title: '链接',
          key: 'path',
          minWidth: 300
        },
        {
          title: '操作ip',
          key: 'ip',
          minWidth: 150
        },
        {
          title: '类型',
          key: 'type',
          minWidth: 100
        },
        {
          title: '操作时间',
          slot: 'add_time',
          minWidth: 150
        }
      ]
    }
  },
  components: {},
  // 支柱道具属性
  props: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created () {
    this.getSearchAdminListRequest()
    this.getSystemLogListRequest()
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted () {
  },
  // 方法集合
  methods: {
    // 列表
    getSystemLogListRequest () {
      this.loading = true
      getSystemListApi(this.formValidate).then(async (res) => {
        let data = res.data
        this.tabList = data.list
        this.total = data.count
        this.loading = false
      }).catch((res) => {
        this.loading = false
        this.$Message.error(res.msg)
      })
    },
    // 搜索条件
    getSearchAdminListRequest () {
      searchAdminApi().then(async (res) => {
        this.dataList = res.data.info
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 时间搜索处理
    handleSelectChange (tab) {
      this.formValidate.data = tab
      this.timeVal = []
      this.formValidate.page = 1
      this.getSystemLogListRequest()
    },
    handleChangeTime (e) {
      this.timeVal = e
      this.formValidate.data = this.timeVal.join('-')
      this.formValidate.page = 1
      this.getSystemLogListRequest()
    },
    // 名称搜索
    handleSearch () {
      this.formValidate.page = 1
      this.getSystemLogListRequest()
    },
    // 分页搜索
    handlePageChange (index) {
      this.formValidate.page = index
      this.getSystemLogListRequest()
    }
  },
  // 生命周期 - 更新之前
  beforeUpdate () {
  },
  // 生命周期 - 更新之后
  updated () {
  },
  // 过滤器
  filters: {
    formatDate (time) {
      if (time !== 0) {
        let date = new Date(time * 1000)
        return formatDate(date, 'yyyy-MM-dd hh:mm')
      }
    }
  },
  // 生命周期 - 创建之前
  beforeCreate () {
  },
  // 生命周期 - 挂载之前
  beforeMount () {
  },
  // 生命周期 - 销毁之前
  beforeDestroy () {
  },
  // 生命周期 - 销毁完成
  destroyed () {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated () {
  },
  // 离开的时候触发
  deactivated () {
  }
}
</script>

<style scoped>
</style>
